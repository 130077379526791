// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDU0pfcMMUgQSFjFvjaTi3RfVaGG35tzbM",
  authDomain: "renttime-87ae5.firebaseapp.com",
  projectId: "renttime-87ae5",
  storageBucket: "renttime-87ae5.appspot.com",
  messagingSenderId: "184794085531",
  appId: "1:184794085531:web:016ab72ef7e6f9ded5ae3e",
  measurementId: "G-PWQSBR2M4X",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
